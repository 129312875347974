@use '@angular/material' as mat;

@mixin issue-view-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);


  .overview-table-container {

    .mat-table {

      .mat-column-severity {
        max-width: 4rem;
      }

      .mat-column-assetName {
        min-width: 9rem;
      }

      .mat-column-divisionName {
        max-width: 8rem;
      }

      .mat-column-earliestDate,
      .mat-column-latestDate {
        max-width: 10rem;
      }

      .mat-column-oemVin {
        min-width: 10rem;
      }

      .mat-column-category {
        max-width: 8rem;
      }

      .mat-column-component {
        max-width: 9rem;
      }

      .mat-column-condition {
        max-width: 10rem;
      }

      .mat-column-source,
      .mat-column-events {
        max-width: 6rem;
      }

      .mat-column-events {
        justify-content: right;

        span {
          margin-right: 1.125rem;
        }

        .mat-sort-header-content {
          text-align: right;
        }
      }

      .mat-column-issueDetails {
        max-width: 9rem;
        DIV {
          text-transform: uppercase;
        }
      }
    }
  }
}