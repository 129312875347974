@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined'); // needed for the sidenav.
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
html,
body {
  height: 100%;
}
body {
  margin: 0;
}
