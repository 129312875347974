@use '@angular/material' as mat;

@mixin dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .dialog-container {
    .info-icon {
      color: mat.get-color-from-palette($accent);
      cursor: pointer;
      float: right;
    }

    .dialog-content-container {
      @include mat.elevation(5);
      max-width: 33vw;

      .close-button {
        float: right;
      }
    }
  }
}
