@use '@angular/material' as mat;


@mixin page-title-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .page-title {
    padding-left: 0.875rem;

    h1 {
      @include mat.typography-level($zonar-default-typography, title);
      font-weight: lighter;
    }
  }
}