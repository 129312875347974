@use "@angular/material" as mat;
@import "@zonar-ui/material/zonar-ui-material.theme";
@import "@zonar-ui/core/zonar-ui-core.theme";
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import "@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme";
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/date-picker/zonar-ui-date-picker.theme';
@import '@zonar-ui/filter/zonar-ui-filter.theme';
@import '@zonar-ui/table/zonar-ui-table.theme';
@import '@zonar-ui/asset-properties/zonar-ui-asset-properties.theme';

@import '../app/components/health-info-dialog/health-info-dialog/health-info-dialog.theme';
@import '../app/components/asset-issues-summary/assets-issues-summary.theme';
@import '../styles/sidenav-override.scss';
@import '../app/containers/overview-container/overview-container.theme';
@import '../app/containers/status-container/status-container.theme';
@import '../app/components/page-title/page-title.theme';
@import './common-table.theme.scss';

@mixin apply-zonar-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include health-info-dialog-theme($theme);
  @include zonar-ui-date-picker-theme($theme);
  @include zonar-ui-table-theme($theme);
  @include zonar-ui-asset-properties-theme($theme);
  @include page-title-theme($theme);
  @include overview-theme($theme);
  @include common-table-theme($theme);
  @include status-theme($theme);

  .mat-sidenav-content,
  .content-container {
    background-color: mat.get-color-from-palette($primary, 200);
  }

  @include sidenav-override($zonar-default-theme);

}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-default-theme);
}

.content-container {
  padding: 2rem;
}

.app-container-mobile {
  .content-container {
    margin: 0 0.75em;
    padding: 0.5rem;
  }

  .mat-tab-body-content {
    min-height: 80vh;
  }
}

.cdk-overlay-pane {
  .mat-select-panel {
    max-height: fit-content;
  }

  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
  .mat-option-text {
    font-size: medium;
  }
}

.filter-bar-wrapper {
  margin-bottom: 1rem;
  .custom-dropdown-class {
    max-width: 170px;
  }
}