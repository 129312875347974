@use '@angular/material' as mat;


@mixin sidenav-override($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .sidenav-inner,
  .menu-item-button,
  .expand-menu-icon {
    padding-right: 1.09rem;
  }

  .sidenav-footer,
  .menu-item-button,
  .footer-menu-item-collapsed,
  .collapse-button-collapsed {
    .mat-button-wrapper {
      padding: unset;
      margin: unset;
      min-width: unset;
      line-height: unset;
      font-weight: unset; 
    }
  }

}
