@use '@angular/material' as mat;


@mixin feature-dependency-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .feature-dependency {
    display: flex;

    .feature-dependency-label {
      margin-left: 0.5rem;
    }
  }
}