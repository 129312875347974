@use '@angular/material' as mat;

@import 'src/app/components/mobile-drawer/mobile-drawer.component.theme';
@import 'src/app/components/card/card.theme';
@import 'src/app/components/dialog/dialog.theme';
@import 'src/app/components/severity-counter/severity-counter.component.theme';
@import 'src/app/components/categories-chart/categories-chart.component.theme';
@import 'src/app/components/quick-links/quick-links.component.theme';
@import 'src/app/components/issue-view-table/issue-view-table.theme';
@import 'src/app/components/asset-issues-summary/assets-issues-summary.theme';
@import 'src/app/components/asset-summary-table/asset-summary-table.theme';

@mixin overview-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $zcGunmetal: #49555E; // change as needed

  .overview-container {
    @include mobile-drawer-theme($theme);
    @include card-theme($theme);
    @include dialog-theme($theme);
    @include severity-counter-theme($theme);
    @include assets-issues-summary-theme($theme);
    @include categories-chart-theme($theme);
    @include quick-links-theme($theme);
    @include issue-view-table-theme($theme);
    @include asset-summary-table-theme($theme);

    .data-viz-wrapper {
      margin-bottom: 1rem;
      justify-content: space-between;

      .data-viz-child {
        .mat-card {
          margin: 0;
          height: 100%;
        }
      }
    }

    .skeleton-bar {
      padding-left: 3%;
    }

    .mat-tab-nav-bar {
      margin-bottom: 1.35rem;
      border-bottom-color: mat.get-color-from-palette($primary, 600);

      .mat-tab-link {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($primary, darker);
        text-decoration: none;
        opacity: 1;
      }

      .mat-tab-link:hover {
        background-color: mat.get-color-from-palette($accent, hover);
        color: mat.get-color-from-palette($primary, darker);
        border-bottom-width: 0.125rem;
        border-bottom-color: mat.get-color-from-palette($foreground, base);
        border-bottom-style: solid;
      }

      .mat-tab-label-active {
        color: mat.get-color-from-palette($foreground, base);
        opacity: 1;
      }

      .mat-ink-bar {
        background-color: mat.get-color-from-palette($accent);
      }
    }
    
  }
}
