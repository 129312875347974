@use "@angular/material" as mat;


@mixin health-info-dialog-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $foreground: map-get($theme, foreground);



    .close-button {
        @include mat.typography-level($zonar-default-typography, body-2);
        padding: 4px 20px !important;
        color: mat.get-color-from-palette($primary, 100);
        background-color: mat.get-color-from-palette($accent, default);
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }

    .close-button:hover {
        background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
    }
}