@use '@angular/material' as mat;

@import './components/severity-counter-item/severity-counter-item.component.theme';

@mixin severity-counter-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .title-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .card-title {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
  }

  .severity-dialog {
    margin-left: auto;
  }

  .severity-container {
    min-width: min-content;

    .severity-dialog {
      flex-grow: 1;
      h4 {
        @include mat.typography-level($zonar-default-typography, subheading-1);
        font-weight: 400;
      }
    }

    @include severity-counter-item-theme($theme);

    .severity-counter-item {
      &.minor {
        margin-top: 1rem;
      }
    }

    .skeleton-grid-container {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;

      ngx-skeleton-loader {
        align-self: center;
      }
    }
  }
}
