@use '@angular/material' as mat;

@mixin assets-issues-summary-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .custom-issues-h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
  }

  .custom-issues-p {
    font-weight: 400;
    font-size: 24px;
    line-height: 31.92px;
  }
  .custom-most-issues-p {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    line-height: 2.5rem;
    .asset-name-text {
      font-size: 1.5rem;
    }
  }

  .view-link {
    text-decoration: underline;
    font-weight: normal;
    color: mat.get-color-from-palette($accent, 400);
  }

  .assets-issues-summary {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }

  .card-container,
  .asset-average-container,
  .asset-most-issues-container {
    flex: 1 1 auto;
  }

  .custom-mat-card {
    text-align: center;
    height: 100%;
  }
}
