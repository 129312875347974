@use '@angular/material'as mat;

@mixin mobile-drawer-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mobile-drawer-open-button-container {
    margin-bottom: 1rem;
  }

  .mobile-drawer-panel {
    &.mat-sidenav {
      border-left: none;
      padding: 1rem;

      .drawer-header {
        .drawer-header-title {
          font-size: mat.font-size($zonar-default-typography, 'subheading-1');
        }
      }

      .drawer-content-container {
        overflow-y: auto;

        .zui-filters-card {
          @include mat.elevation(0);
          padding: 0;
          display: flex;
          flex-flow: column-reverse;
          gap: 1rem;

          zui-date-picker,
          zui-asset-filter,
          .mat-form-field {
            max-width: 100%;
            min-width: 100%;
          }

          app-dropdown,
          .custom-dropdown-class {
            max-width: 100%;
            min-width: 100%;
          }
        }
      }
    }
  }
}