@use '@angular/material' as mat;

@mixin asset-summary-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $zonar-gray: map-get($theme, primary);

  .asset-summary-cards-container {
    h4 {
      @include mat.typography-level($zonar-default-typography, subheading-1);
      font-weight: 400;
    }

    .table-legend {
      margin-top: 0;
      padding-left: 0;
      padding-bottom: 1rem;
    }

    .asset-summary-card {
      @include mat.elevation(3);
      margin-bottom: 1rem;

      .mat-card-content {
        @include mat.typography-level($zonar-default-typography, body-1);
        font-size: 1rem;
        margin-bottom: 0;

        .asset-summary-row {
          padding: 0.5rem 0;
          border-bottom: 1px solid get-mat-color($primary, 300);

          label {
            font-weight: 600;
            display: flex;
            gap: 4px;
            align-items: center;
          }
        }

        .view-link {
          text-decoration: underline;
          font-weight: normal;
          color: mat.get-color-from-palette($accent, 400);
        }

        .mat-icon {
          font-size: 18px;
          width: 18px;
          height: 18px;

          &.stop {
            color: mat.get-color-from-palette($warn, default);
          }

          &.warning {
            color: mat.get-color-from-palette($warn, 200);
          }
        }

        .mat-expansion-panel {
          @include mat.elevation(0);
          background-color: #e6f2f8;
          .mat-expansion-panel-body {
            padding: 0px;
            max-height: 50rem;
            overflow-y: auto;
          }
          .issue-card {
            padding: 0 1rem;
            border-bottom: 0.5em solid #fff;
          }
        }

        .list-of-cards-skeleton-container {
          .mat-card {
            margin-bottom: 1.5em;
          }
        }

        // Adding some styles to ensure that the placeholder element does not affect the display of the list
        .scroll-end-placeholder {
          position: relative;
          height: 200px;
          top: -200px;
          width: 100%;
          pointer-events: none;
        }
      }

      .list-of-cards-skeleton-container {
        .mat-card {
          margin-bottom: 1.5em;
        }
      }
    }
  }
}