@use '@angular/material' as mat;

@mixin severity-counter-item-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .severity-counter-item {
        &.critical {
            .severity-button {
                background-color: mat.get-color-from-palette($warn, default);
            }
        }

        &.minor {
            .severity-button {
                background-color: mat.get-color-from-palette($warn, 200);
            }
        }

        &.fade {
            opacity: 20%;
            .severity-button {
                cursor: unset;
            }
        }

        .severity-button {
            @include mat.typography-level($zonar-default-typography, title);
            font-weight: 400;
            color: mat.get-color-from-palette($primary, 100);
            text-align: center;
            border-radius: 0.5rem;
            min-width: 5rem;
            max-width: 10rem;
            padding: 0.5rem 0.75rem;
            cursor: pointer;
        }

        .severity-label {
            margin: 0;
        }
    }
}