@use '@angular/material' as mat;

@mixin categories-chart-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .category-chart-container {
    .categories-chart {
      height: 10rem;
    }

    .category-tooltip-container {
      > * {
        display: flex;
        flex: 1 100%;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flext-start;
        gap: 0.25rem;
      }

      .critical {
        margin-bottom: 0.25rem;

        &:before {
          @include icon();
          content: 'stop';
          color: mat.get-color-from-palette($warn, default);
        }
      }

      .minor {
        &:before {
          @include icon-svg();
          content: '';
          color: mat.get-color-from-palette($warn, 200);
        }
      }

      .value {
        flex: 2;
        font-weight: bold;
        text-align: right;
      }
    } 
  }
}

@mixin icon {
  font-family: 'Material Icons';
  font-size: 1.25rem;
}

@mixin icon-svg {
  width: 20px;
  height: 20px;
  background: url('../../../assets/images/warning.svg') no-repeat center center;
  background-size: 14px;
}