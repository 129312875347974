@use '@angular/material' as mat;

@mixin quick-links-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    
    .quick-links-card {
        h4 {
            font-size: mat.font-size($zonar-default-typography, 'body-1');
            font-weight: mat.font-weight($zonar-default-typography, 'title');
        }

        .quick-link-row {
            .new-tab-icon {
                color: mat.get-color-from-palette($primary, 600);
            }

            hr {
                margin: 0.65rem 0;
            }
        }

        .dialog-container {
            width: 25rem;
        }
    }
}
